import React, { useMemo } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { StaticQuery, graphql, navigate } from 'gatsby';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { Box, Input, useBreakpoint } from '@hausgold/designsystem';
import { cloneDeep } from 'lodash';
import Navigation from './Navigation';
import ContentfulButton from './ContentfulButton';

const Header = ({
  template,
  heroImage,
  title,
  subTitle,
  size,
  className,
  hideGradientOverlay,
  showAlternativePageHeader,
  altHeaderButtonHeadline,
  altHeaderInput,
  altHeaderButton,
  children,
  breadcrumb,
  headerStyles = { marginTop: 7 },
  breadcrumbStyles,
}) => {
  const heroSrc = getSrc(heroImage);
  const hasHeaderInteraction = altHeaderButton?.length === 2;
  const isLargerThanLG = useBreakpoint('lg', '>');
  const isLargerThanXL = useBreakpoint('xl', '>');

  const navi = (
    <StaticQuery
      query={graphql`
        query NavigationQuery {
          ratgeber: contentfulNavigation(slug: { eq: "ratgeber" }) {
            entries {
              ... on ContentfulCategory {
                internal {
                  type
                }
                id
                title
                slug
              }
            }
          }
          navigation: contentfulNavigation(slug: { eq: "hauptnavigation" }) {
            entries {
              ... on ContentfulCategory {
                internal {
                  type
                }
                id
                title
                slug
              }
              ... on ContentfulNavigation {
                internal {
                  type
                }
                id
                title
                slug
                entries {
                  ... on ContentfulCategory {
                    internal {
                      type
                    }
                    id
                    title
                    slug
                  }
                  ... on ContentfulPage {
                    internal {
                      type
                    }
                    id
                    slug
                    title
                    page {
                      slug
                      title
                    }
                  }
                }
              }
              ... on ContentfulPage {
                internal {
                  type
                }
                id
                title
                slug
              }
            }
          }
          maklerPages: allContentfulPage(
            filter: { template: { eq: "Makler" } }
          ) {
            edges {
              node {
                id
                title
                slug
              }
            }
          }
        }
      `}
      render={(data) => (
        <Navigation
          template={template}
          entries={data}
          className={
            (!heroSrc ||
              // Show scrolled header (white instead of transparent) for alternative header on LG screens.
              (isLargerThanLG && showAlternativePageHeader)) &&
            'scrolled'
          }
          contrastMode={!isLargerThanXL && showAlternativePageHeader}
        />
      )}
    />
  );

  /*
   * Overwrites formatting props from graphQl HeroImage query.
   * As the page is used for at least 3 variants - so this Header knows 2 variants - we
   * can not properly change the origin graphQL data and need this workaround.
   */
  const formattedHeroImage = useMemo(() => {
    const tempImageProps = cloneDeep(heroImage);

    if (tempImageProps?.tempImageProps) {
      tempImageProps.gatsbyImageData.layout = 'constrained';
      tempImageProps.gatsbyImageData.aspectRatio = 3 / 4;
      tempImageProps.gatsbyImageData.width = undefined;
    }

    return tempImageProps;
  }, [heroImage]);

  const handleAltHeaderEnter = (event) => {
    if (event.key === 'Enter' && altHeaderButton?.[1]) {
      navigate(altHeaderButton[1]);
    }
  };

  // This header variant is only used for special pages.
  if (showAlternativePageHeader) {
    return (
      <header>
        <div
          style={{
            backgroundColor: `#E5F0F8`,
          }}
          className={`header text-center d-flex justify-content-center align-items-center align-items-lg-end fixed-small ${className}`}
        >
          {navi}
          <div className="d-flex justify-content-center w-100">
            <div className="text-center text-lg-left align-content-center col-12 col-lg-6 px-4 pr-lg-6 pl-lg-7">
              {title && (
                <h1 className="display-2 text-break font-weight-normal text-shadow mb-6">
                  {title}
                  <br />
                  <small className="font-weight-normal d-block mt-6">
                    {subTitle}
                  </small>
                </h1>
              )}
              {!!altHeaderButtonHeadline && (
                <div className="mb-4">{altHeaderButtonHeadline}</div>
              )}
              {(hasHeaderInteraction || !!altHeaderInput) && (
                <div className="d-flex justify-content-center justify-content-lg-start align-items-stretch">
                  {!!altHeaderInput && (
                    <Input
                      paddingY={3}
                      border="4px solid"
                      borderColor="blue.400"
                      height="100%"
                      width="180px"
                      fontSize="lg"
                      borderLeftRadius={2}
                      borderRightRadius={hasHeaderInteraction ? 0 : 2}
                      placeholder={altHeaderInput}
                      onKeyDown={handleAltHeaderEnter}
                    />
                  )}
                  {hasHeaderInteraction && (
                    <ContentfulButton
                      borderLeftRadius={altHeaderInput ? 0 : undefined}
                      minWidth="180px"
                      entry={{
                        title: altHeaderButton[0],
                        url: altHeaderButton[1],
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="col-6 d-none d-lg-block pr-0">
              <GatsbyImage
                style={{ width: '100%' }}
                image={getImage(formattedHeroImage)}
                alt={heroImage?.title}
              />
            </div>
          </div>
        </div>
      </header>
    );
  }

  const background =
    hideGradientOverlay && heroSrc
      ? `url(${heroSrc})`
      : `linear-gradient( rgba(22,148,244,.5), rgba(22,148,244,.5) ), url(${heroSrc})`;

  return heroSrc ? (
    <header>
      <div
        style={{
          backgroundImage: background,
        }}
        className={`header text-center d-flex justify-content-center align-items-center header-${
          size || 'md'
        } ${className}`}
      >
        {navi}
        {size !== 'lg' && (
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xs="12" lg="8">
                {title && (
                  <h1 className="display-2 text-break text-center text-white text-shadow">
                    {title}
                    <br />
                    <small className="font-weight-semibold d-block mt-4">
                      {subTitle}
                    </small>
                  </h1>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </div>
      {size === 'lg' && (
        <Container className="wizardheader-margin">
          <Row>
            <Col className="text-center">
              {title && (
                <h1
                  className="display-2 text-break text-center text-white text-shadow mb-6 mx-auto"
                  style={{ maxWidth: '728px' }}
                >
                  {title}
                  <br />
                  <small className="font-weight-semibold d-block mt-4">
                    {subTitle}
                  </small>
                </h1>
              )}
            </Col>
          </Row>
          {children}
        </Container>
      )}
    </header>
  ) : (
    <header className={`header container mt-${headerStyles.marginTop}`}>
      {navi}
      {breadcrumb ? (
        <Box my={{ base: 0, md: 4 }} {...breadcrumbStyles}>
          {breadcrumb}
        </Box>
      ) : null}
      {title && (
        <h1 className="display-2">
          {title}
          <br />
          <small className="font-weight-semibold d-block mt-4">
            {subTitle}
          </small>
        </h1>
      )}
    </header>
  );
};

export default Header;
