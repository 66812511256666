import React, { useState } from 'react';
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import get from 'lodash/get';
import Link from './Link';
import Logo from './Logo';
import useScrollHandler from '../hooks/useScrollHandler';
import OutboundLink from './OutboundLink';

const Navigation = ({ template, className, contrastMode, entries }) => {
  const scroll = useScrollHandler();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar
      dark
      expand="xl"
      fixed="top"
      className={`px-0 text-left ${scroll ? '' : 'scrolled'} ${
        contrastMode ? 'contrast' : ''
      } ${isOpen ? 'open' : ''} ${className}`}
    >
      <Container fluid className="justify-content-between">
        <NavbarBrand
          href="/"
          className="d-none d-md-block mr-md-0 py-0 px-xl-3 px-md-6 active"
        >
          <Logo width="150" className="logo" />
        </NavbarBrand>
        <NavbarBrand
          href="/"
          className="d-block d-md-none mr-md-6 py-0 px-3 px-md-6 active"
        >
          <svg
            className="signet"
            width="30"
            height="29"
            viewBox="0 0 19 18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="a"
              d="M13.701 13.622c-2.137 1.694-2.715 2.093-4.73 3.223h10.78s-.924-3.187-2.303-6.846c-1.2 1.312-1.881 2.146-3.747 3.623z"
              fill="#f46364"
            />
            <path
              d="M.98 17.404S5.53.01 10 .01c1.732 0 3.63 3.089 5.243 6.665-3.195 3.938-9.005 8.425-12.706 10.728H.981z"
              fill="#F46364"
            />
          </svg>
        </NavbarBrand>

        <RenderTelephoneLink
          template={template}
          scroll={!scroll || contrastMode}
        />
        {template !== 'Makler' && (
          <OutboundLink
            className="btn btn-navbar d-block d-xl-none"
            style={{ background: '#fff', margin: '-7px' }}
            href="https://immobilienbewertung.hausgold.de/2/"
            eventCategory="Special Button"
            eventAction="Click"
            eventLabel="Navigation XS/SM/MD Wizard"
          >
            <span className="d-none d-md-inline-block">Kostenlose</span>{' '}
            Bewertung
          </OutboundLink>
        )}
        <NavbarToggler className="mr-3" onClick={toggle} />
        <Collapse navbar isOpen={isOpen}>
          <div className="d-xl-flex justify-content-between w-100">
            <Nav navbar className="order-1 order-md-2">
              {entries.navigation.entries.map((entry) => {
                const shadowEntry =
                  entry.slug === 'ratgeber' ? entries.ratgeber : entry;
                return shadowEntry.entries ? (
                  <UncontrolledDropdown
                    key={entry.slug}
                    inNavbar
                    className="custom-nav-style"
                  >
                    <DropdownToggle className="nav-link" caret tag="a">
                      {entry.title}
                    </DropdownToggle>
                    <DropdownMenu>
                      {shadowEntry.entries.map((subEntry) => {
                        const path =
                          subEntry.internal.type === 'ContentfulPage'
                            ? [
                                get(subEntry, 'page[0].slug', null),
                                subEntry.slug,
                              ]
                                .filter(Boolean)
                                .join('/')
                            : [subEntry.slug].filter(Boolean).join('/');
                        return (
                          <Link
                            key={subEntry.id || '1'}
                            to={`/${path}/`}
                            tabIndex="0"
                            role="menuitem"
                            className="dropdown-item"
                          >
                            {subEntry.title}
                          </Link>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : (
                  <NavItem key={entry.slug}>
                    <Link
                      to={`/${entry.slug}/`}
                      role="menuitem"
                      className="nav-link"
                    >
                      {entry.title}
                    </Link>
                  </NavItem>
                );
              })}
              {entries?.maklerPages?.edges.length > 0 && (
                <UncontrolledDropdown inNavbar className="custom-nav-style">
                  <DropdownToggle
                    className="nav-link"
                    caret={entries?.maklerPages?.edges.length > 0}
                    tag="a"
                  >
                    Für Makler
                  </DropdownToggle>

                  <DropdownMenu>
                    {entries.maklerPages.edges.map((maklerPage) => (
                      <Link
                        key={maklerPage.node.id}
                        to={`/fuer-makler/${maklerPage.node.slug}/`}
                        tabIndex="0"
                        role="menuitem"
                        className="dropdown-item"
                      >
                        {maklerPage.node.title}
                      </Link>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>

            <SocialNavigationPart template={template} />
          </div>
        </Collapse>
      </Container>
    </Navbar>
  );
};

const RenderTelephoneLink = ({ template, scroll }) => {
  if (template === 'Makler')
    return (
      <OutboundLink
        eventCategory="Special Button"
        eventAction="Click"
        eventLabel="Navigation SM/MD Phone Maklerportal"
        className="phone d-none d-sm-block d-xl-none"
        href="tel:+4940226161423"
      >
        <svg
          className="mr-2"
          style={{ width: '24px', height: '24px' }}
          viewBox="0 0 24 24"
        >
          <path
            fill="#fff"
            d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
          />
        </svg>
        040 22 6161 423
      </OutboundLink>
    );

  return (
    <OutboundLink
      eventCategory="Special Button"
      eventAction="Click"
      eventLabel="Navigation SM/MD Phone"
      className="phone d-none d-sm-block d-xl-none"
      href="tel:+494022616140"
      style={{ color: scroll ? 'black' : '#fff' }}
    >
      <svg
        className="mr-2"
        style={{ width: '24px', height: '24px', color: '#1694f4' }}
        viewBox="0 0 24 24"
      >
        <path
          className="bs-breakout"
          fill="#fff"
          d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
        />
      </svg>
      040 - 22 61 61 40
    </OutboundLink>
  );
};

const SocialNavigationPart = ({ template }) => {
  if (template === 'Makler')
    return (
      <ul className="navbar-nav pr-md-3 order-2 oder-md-1 custom-nav-style">
        <li className="nav-item d-block d-xl-block custom-nav-style">
          <OutboundLink
            eventCategory="Special Button"
            eventAction="Click"
            eventLabel="Navigation Phone Maklerportal"
            className="nav-link phone d-sm-none d-xl-inline-block"
            href="tel:+4940226161423"
          >
            <svg
              className="mr-2"
              style={{ width: '24px', height: '24px', color: '#1694f4' }}
              viewBox="0 0 24 24"
            >
              <path
                className="bs-breakout"
                fill="#fff"
                d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
              />
            </svg>
            040 22 6161 423
          </OutboundLink>
        </li>
        <li className="nav-item pl-2 mr-3 d-block d-sm-none custom-nav-style">
          <OutboundLink
            className="nav-link"
            eventCategory="Special Button"
            eventAction="Click"
            eventLabel="Navigation XS/SM Maklerportal"
            href="https://maklerportal-next.hausgold.de"
            style={{ background: '#fff' }}
          >
            <svg
              className="mr-2"
              style={{ width: '22px', height: '22px' }}
              viewBox="0 0 24 24"
            >
              <path
                fill="#1694f4"
                d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
              />
            </svg>
            Maklerportal-Login
          </OutboundLink>
        </li>
        {/* <li className="nav-item mr-3 d-none d-xl-block"> */}
        <li className="nav-item mr-3 d-none d-sm-block">
          <OutboundLink
            className="btn btn-outline-light"
            href="https://maklerportal-next.hausgold.de"
            style={{ background: 'transparent' }}
            eventCategory="Special Button"
            eventAction="Click"
            eventLabel="Navigation LG/XL Maklerportal"
          >
            <svg style={{ width: '22px', height: '22px' }} viewBox="0 0 24 24">
              <path
                className="bs-breakout"
                fill="#1694f4"
                d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
              />
            </svg>
            Maklerportal-Login
          </OutboundLink>
        </li>
      </ul>
    );
  return (
    <ul className="navbar-nav pr-md-3 order-2 oder-md-1 custom-nav-style">
      <li className="nav-item d-block d-md-none">
        <OutboundLink
          eventCategory="Special Button"
          eventAction="Click"
          eventLabel="Navigation Phone"
          className="nav-link phone d-sm-none d-xl-inline-block"
          href="tel:+494022616140"
        >
          <svg
            className="mr-2"
            style={{ width: '24px', height: '24px' }}
            viewBox="0 0 24 24"
          >
            <path
              fill="#fff"
              d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
            />
          </svg>
          040 - 22 61 61 40
        </OutboundLink>
      </li>
      <li className="nav-item d-none d-xl-block custom-nav-style">
        <OutboundLink
          eventCategory="Special Button"
          eventAction="Click"
          eventLabel="Navigation Phone"
          className="nav-link phone d-sm-none d-xl-inline-block"
          href="tel:+494022616140"
        >
          <svg
            className="mr-2"
            style={{ width: '24px', height: '24px', color: '#1694f4' }}
            viewBox="0 0 24 24"
          >
            <path
              className="bs-breakout"
              fill="#fff"
              d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
            />
          </svg>
          040 - 22 61 61 40
        </OutboundLink>
      </li>
      <li className="nav-item d-none d-xl-block custom-nav-style">
        <OutboundLink
          eventCategory="Special Button"
          eventAction="Click"
          eventLabel="Navigation LG/XL Wizard"
          className="btn btn-navbar"
          style={{ background: '#fff' }}
          href="https://immobilienbewertung.hausgold.de/2/"
        >
          <span className="d-none d-xxl-inline-block">Kostenlose</span>{' '}
          <span>Bewertung</span>
        </OutboundLink>
      </li>
    </ul>
  );
};

export default Navigation;
