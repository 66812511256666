import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import { Divider, Stack } from '@hausgold/designsystem';
import cn from 'classnames';
import ContentBlock from '../components/ContentBlock';
import ContentfulFaqList from '../components/ContentfulFaqList';
import ExitIntent from '../components/ExitIntent';
import ExitIntentModal from '../components/ExitIntentModal';
import Formwizard from '../components/Formwizard';
import Header from '../components/Header';
import Layout from '../components/Layout';
import RelatedContent from '../components/RelatedContent';
import SEO from '../components/SEO';
import WizardCard from '../components/WizardCard';
import { collectFaqs } from '../utilities';
import AuthorBox from '../components/AuthorBox';

const PageTemplate = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const site = get(data, 'site');
  const heroImage = get(page, 'heroImage');
  const altHeroImage = get(page, 'altHeroImage');
  const formwizard = get(page, 'formwizard');
  const showFormWizard = get(page, 'showFormWizard') !== false; // Assign 'true' to 'showFormWizard' if 'article.showFormWizard' is 'null' or 'true', 'false' otherwise.
  const exitIntent = get(page, 'exitIntent');
  const relatedContent = get(page, 'relatedPages');
  const faqList = get(page, 'faqList');

  const formwizardPosition = get(formwizard, 'position');
  const formwizardUrl = get(formwizard, 'url');
  const contentBlocks = get(page, 'contentBlocks');
  const parentPage = get(page, 'page[0]') || page;
  const childPages = get(page, 'childPages') || get(parentPage, 'childPages');
  const [showPopup, setShowPopup] = useState(false);
  const showAlternativePageHeader =
    get(page, 'useArticleSchema') && get(page, 'template') === 'Page';

  // Interim solution for at ch
  const alternatePages = {
    index: [
      { lang: 'de-DE', path: '' },
      { lang: 'de-CH', path: 'ch/' },
      { lang: 'de-AT', path: 'at/' },
    ],
    verkauf: [
      { lang: 'de-DE', path: 'immobilienverkauf/' },
      { lang: 'de-CH', path: 'ch/immobilienverkauf-schweiz/' },
      { lang: 'de-AT', path: 'at/immobilienverkauf-oesterreich/' },
    ],
    bewertung: [
      { lang: 'de-DE', path: 'immobilienbewertung/' },
      { lang: 'de-AT', path: 'at/immobilienbewertung-oesterreich/' },
      { lang: 'de-CH', path: 'ch/immobilienbewertung-schweiz/' },
    ],
    makler: [
      { lang: 'de-DE', path: 'immobilienmakler/' },
      { lang: 'de-CH', path: 'ch/immobilienmakler-schweiz/' },
      { lang: 'de-AT', path: 'at/immobilienmakler-oesterreich/' },
    ],
  };

  const alternatePagesMapping = {
    '/': alternatePages.index,
    ch: alternatePages.index,
    at: alternatePages.index,
    immobilienverkauf: alternatePages.verkauf,
    'immobilienverkauf-schweiz': alternatePages.verkauf,
    'immobilienverkauf-oesterreich': alternatePages.verkauf,
    immobilienbewertung: alternatePages.bewertung,
    'immobilienbewertung-schweiz': alternatePages.bewertung,
    'immobilienbewertung-oesterreich': alternatePages.bewertung,
    immobilienmakler: alternatePages.makler,
    'immobilienmakler-oesterreich': alternatePages.makler,
    'immobilienmakler-schweiz': alternatePages.makler,
  };

  useEffect(() => {
    const removeExitIntent = ExitIntent({
      threshold: 10,
      eventThrottle: 100,
      onExitIntent: () => {
        setShowPopup(true);
      },
    });
    return () => {
      removeExitIntent();
    };
  });

  const faqItems = collectFaqs({
    faq: faqList,
    contentBlocks,
  });

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
  ];

  // Check if page is a child page
  const parentPath = page.page?.[0]?.slug;

  if (parentPath) {
    breadcrumbs.push({
      title: page.page[0].title,
      url: `${site.siteMetadata.siteUrl}/${parentPath}/`,
    });
  }

  breadcrumbs.push({
    title: page.title,
    url: `${site.siteMetadata.siteUrl}/${parentPath ? `${parentPath}/` : ''}${
      page.slug
    }/`,
  });

  return (
    <Layout
      location={location}
      cookieConsent
      contentStyles={{
        marginX: 'auto',
        maxW: showFormWizard ? 'inherit' : '960px',
      }}
      header={
        <Header
          heroImage={showAlternativePageHeader ? altHeroImage : heroImage}
          title={page.headline}
          subTitle={page.subTitle}
          author={{ ...page.author, updatedAt: page.updatedAt }}
          size={showFormWizard && formwizardPosition === 'header' ? 'lg' : 'md'}
          showAlternativePageHeader={showAlternativePageHeader}
          altHeaderButtonHeadline={get(page, 'headlineButtonHeadline')}
          altHeaderInput={get(page, 'headlineInput')}
          altHeaderButton={get(page, 'headlineRedirectButton')}
        >
          {!showAlternativePageHeader &&
            showFormWizard &&
            formwizardPosition === 'header' && (
              <WizardCard>
                <Formwizard
                  position={formwizardPosition}
                  location={location}
                  url={formwizardUrl}
                />
              </WizardCard>
            )}
        </Header>
      }
    >
      <Helmet>
        <meta name="content-entity" content="page" />
        <meta name="content-id" content={page.contentful_id} />
      </Helmet>

      <SEO
        entity={{ ...page, imageSrc: getSrc(heroImage) }}
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type="page"
        description={page.metaDescription}
        indexable={page.indexable}
        breadcrumbs={breadcrumbs}
        faqItems={faqItems}
      />

      <Helmet>
        {alternatePagesMapping[page.slug] &&
          alternatePagesMapping[page.slug].map((alternatePage) => (
            <link
              key={`page-${page.slug}-${alternatePage.lang}-${alternatePage.path}`}
              rel="alternate"
              hrefLang={alternatePage.lang}
              href={`https://www.hausgold.de/${alternatePage.path}`}
            />
          ))}
      </Helmet>
      {childPages &&
        alternatePagesMapping[page.slug] &&
        (location.pathname.includes('/ch/') ||
          location.pathname.includes('/at/')) && (
          <Container className="mb-5">
            <ul className="nav justify-content-center">
              <li className="nav-item">
                <a className="nav-link" href={`/${parentPage.slug}/`}>
                  {parentPage.title}
                </a>
              </li>
              {childPages.map((childPage) => (
                <li key={childPage.id} className="nav-item">
                  <a
                    className="nav-link"
                    href={`/${parentPage.slug}/${childPage.slug}`}
                  >
                    {childPage.title}
                  </a>
                </li>
              ))}
            </ul>
          </Container>
        )}
      <Container className="mt-6 mb-5">
        <Row className="justify-content-between">
          <Col
            xs="12"
            lg={showFormWizard && formwizardPosition === 'right' ? 7 : 12}
            className={`order-2 ${
              showFormWizard && formwizardPosition === 'right'
                ? 'order-md-1'
                : 'order-md-2'
            }`}
          >
            <div
              className="body markdown"
              dangerouslySetInnerHTML={{
                __html: get(page, 'body.childMarkdownRemark.html'),
              }}
            />
          </Col>
          {showFormWizard &&
            (formwizardPosition === 'right' ||
              formwizardPosition === 'top') && (
              <Col
                xs="12"
                lg={formwizardPosition === 'right' ? 5 : 12}
                xl={formwizardPosition === 'right' ? 4 : 12}
                className={`order-1 ${
                  formwizardPosition === 'right' ? 'order-md-2' : 'order-md-1'
                }`}
              >
                {formwizardPosition && (
                  <Formwizard
                    position={formwizardPosition}
                    location={location}
                    url={formwizardUrl}
                  />
                )}
              </Col>
            )}
        </Row>
      </Container>
      {page.author && (
        <Container
          className={cn({
            'container-narrow':
              showFormWizard && formwizardPosition !== 'right',
          })} // Copied from below "ContentBlock" container class for equal width
        >
          <AuthorBox
            title={page.author?.title}
            name={`${page.author?.firstName} ${page.author?.lastName}`}
            imageUrl={getSrc(page.author?.image)}
            lastUpdate={page.updatedAt}
          />
          <Divider />
        </Container>
      )}
      {contentBlocks &&
        contentBlocks.map((block) => (
          <ContentBlock
            key={block.id}
            block={block}
            narrow={showFormWizard && formwizardPosition !== 'right'}
            location={location}
            source="page"
          />
        ))}

      {page.author && (
        <Container
          className={cn({
            'container-narrow':
              showFormWizard && formwizardPosition !== 'right',
          })} // Copied from below "ContentBlock" container class for equal width
        >
          <Stack my="4">
            <h2>
              {page.author?.isMale ? 'Über den Autor' : 'Über die Autorin'}
            </h2>
            <AuthorBox
              style={{
                p: '5',
                shadow: 'sm',
              }}
              title={page.author?.title}
              name={`${page.author?.firstName} ${page.author?.lastName}`}
              imageUrl={getSrc(page.author?.image)}
              bio={page.author?.bio?.childMarkdownRemark?.html}
              profileLink={`/experten/${page.author?.slug}`}
              socialMediaLinks={page.author?.socialMedia}
            />
          </Stack>
        </Container>
      )}

      {exitIntent && (
        <ExitIntentModal
          show={showPopup}
          image={exitIntent.image}
          text={exitIntent.text.childMarkdownRemark.html}
          button={exitIntent.button}
          setShowPopup={setShowPopup}
        />
      )}

      <Container className="container-narrow">
        {relatedContent && <RelatedContent content={relatedContent} />}
        {faqList && (
          <>
            <h2>Häufig gestellte Fragen:</h2>
            <ContentfulFaqList entry={faqList} />
          </>
        )}
      </Container>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      useArticleSchema
      contentful_id
      id
      title
      headline
      headlineColor
      subTitle
      headlineButtonHeadline
      headlineInput
      headlineRedirectButton
      indexable
      metaTitle
      metaDescription
      slug
      updatedAt
      createdAt
      template
      childPages {
        id
        slug
        title
      }
      author {
        id
        isMale
        lastName
        firstName
        company
        email
        title
        slug
        tag
        image {
          gatsbyImageData(layout: FULL_WIDTH, width: 800)
          title
        }
        bio {
          childMarkdownRemark {
            html
          }
        }
        freeText {
          childMarkdownRemark {
            html
          }
        }
        socialMedia {
          facebook
          instagram
          website
          twitter
          linkedin
          xing
        }
      }
      page {
        id
        slug
        title
        childPages {
          id
          slug
          title
        }
      }
      showFormWizard
      formwizard {
        position
        url
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      exitIntent {
        image {
          gatsbyImageData(layout: FIXED, width: 170)
          title
        }
        text {
          childMarkdownRemark {
            html
          }
        }
        button {
          title
          url
          color
          size
          shadow
        }
      }
      relatedPages {
        ... on ContentfulPage {
          id
          slug
          title
          metaDescription
          relatedPagesText
          heroImage {
            gatsbyImageData(
              layout: FIXED
              width: 600
              cropFocus: FACES
              resizingBehavior: FILL
            )
          }
          page {
            id
            slug
            title
          }
        }

        ... on ContentfulArticle {
          id
          slug
          title
          metaDescription
          relatedArticlesText
          heroImage {
            gatsbyImageData(
              layout: FIXED
              width: 600
              height: 600
              cropFocus: FACES
              resizingBehavior: FILL
            )
          }
          category {
            slug
          }
          description {
            description
            id
            childMarkdownRemark {
              id
              html
            }
          }
        }
      }
      faqList {
        faqItems {
          id
          answer {
            childMarkdownRemark {
              html
            }
          }
          question
        }
      }
      contentBlocks {
        title
        id
        tocPosition
        entries {
          ... on ContentfulContentBreaker {
            internal {
              type
            }
            id
            headline1
            description
            linkLabel
            link
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          ... on ContentfulDownloadItem {
            internal {
              type
            }
            id
            title
            file {
              file {
                url
              }
              title
            }
            link
          }
          ... on ContentfulDownloadList {
            internal {
              type
            }
            id
            title
            downloadItems {
              title
              file {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulList {
            internal {
              type
            }
            id
            listItems {
              internal {
                type
              }
              children {
                id
              }
              title
              id
              icon
              image {
                gatsbyImageData(layout: FIXED, width: 170)
                title
              }
              text {
                childMarkdownRemark {
                  html
                }
              }
            }
            title
            template
          }
          ... on ContentfulListItem {
            internal {
              type
            }
            children {
              id
            }
            title
            id
            icon
            image {
              gatsbyImageData(layout: FIXED, width: 170)
              title
            }
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulText {
            internal {
              type
            }
            id
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulTextWithImage {
            internal {
              type
            }
            id
            title
            template
            imageAlignment
            body {
              childMarkdownRemark {
                html
              }
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH, width: 800)
              title
            }
          }
          ... on ContentfulFaqList {
            internal {
              type
            }
            id
            faqItems {
              id
              answer {
                childMarkdownRemark {
                  html
                }
              }
              question
            }
          }
          ... on ContentfulButton {
            ...ContentfulButtonFragment
          }
          ... on ContentfulFormwizard {
            internal {
              type
            }
            id
            position
            title
            url
            variant
          }
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
      altHeroImage {
        gatsbyImageData(
          layout: FIXED
          width: 900
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
  }
`;
